:root {
  --white: #fff;
  --oh-orange: #ef5c34;
  --oh-dark-orange: #de532e;
  --oh-light-orange: #f7eee9;
  --oh-white: #f5f5f5;
  --oh-light-cream: #fbf5ed;
  --oh-off-white: #f8f8f8; /* for payment card */
  --oh-black: #555555;
  --oh-black-light: #717171;
  --oh-black-tertiary: rgba(0, 0, 0, 0.36);
  --oh-black-secondary: rgba(0, 0, 0, 0.56);
  --oh-black-primary: rgba(0, 0, 0, 0.86);
  --oh-blue: #66b5de;
  --oh-teal: #95c48c;
  --oh-disabled: #d3d3d3;
  --oh-red: #e87d7c;
  --oh-green: #a3c996;
  --oh-vl-blue: rgba(102, 181, 222, 0.1);
  --oh-gray: rgba(0, 0, 0, 0.12);
  --oh-border-gray: rgba(0, 0, 0, 0.05);
  --oh-shadow-gray: rgba(64, 64, 64, 0.3);
  --oh-box-gray: #fafafa;

  --oh-brand-1: rgba(242, 183, 5, 1);
  --oh-brand-vl-1: rgba(242, 183, 5, 0.1);
  --oh-brand-2: rgba(140, 86, 55, 1);
  --oh-brand-vl-2: rgba(140, 86, 55, 0.1);
  --oh-brand-3: rgba(132, 108, 217, 1);
  --oh-brand-vl-3: rgba(132, 108, 217, 0.1);
  --oh-brand-4: rgba(191, 99, 105, 1);
  --oh-brand-vl-4: rgba(191, 99, 105, 0.1);
  --oh-brand-5: rgba(147, 204, 88, 1);
  --oh-brand-vl-5: rgba(147, 204, 88, 0.1);
  --oh-brand-6: rgba(94, 174, 1, 1);
  --oh-brand-vl-6: rgba(94, 174, 1, 0.1);
  --oh-brand-7: rgba(11, 158, 217, 1);
  --oh-brand-vl-7: rgba(11, 158, 217, 0.1);
  --oh-brand-8: rgba(255, 118, 107, 1);
  --oh-brand-vl-8: rgba(255, 118, 107, 0.1);
  --oh-brand-9: rgba(68, 137, 171, 1);
  --oh-brand-vl-9: rgba(68, 137, 171, 0.1);
}

@font-face {
  font-family: Avenir;
  src: local('Avenir Next'), url('./assets/fonts/AvenirReg.ttf');
  font-display: swap;
}
@font-face {
  font-family: AvenirDemi;
  src: local('Avenir Next'), url('./assets/fonts/AvenirDemi.ttf');
  font-display: swap;
}
@font-face {
  font-family: AvenirMediumItalic;
  src: local('Avenir Next'), url('./assets/fonts/AvenirMed.ttf');
  font-display: swap;
}

@font-face {
  font-family: AvenirMedium;
  src: local('Avenir Next'),
    url('./assets/fonts/AvenirNextRoundedProMedium.ttf');
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Avenir';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

a {
  color: var(--oh-orange);
  cursor: pointer;
  text-decoration: underline;
  font-weight: 700;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Scrollbar customizations currently only for webkit engine */

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.confirm-button,
.cancel-button {
  padding: 10px 20px !important;
  border-radius: 5px !important;
}

.confirm-button {
  background: #f3c520 !important;
  color: #000 !important;
}

.cancel-button {
  background: #fff !important;
  color: #000 !important;
  border: 1px solid #f3c520 !important;
}

.MuiMenuItem-root {
  font-family: 'Avenir' !important;
}

.MuiTable-root {
  margin: 0 !important;
}

.custom-root *{
  margin: 0;
  margin-block-end: 0;
  margin-block-start: 0;
}

.custom-root {margin-top: 4px;}
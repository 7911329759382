.slide-pane__overlay {
  z-index: 1;
}

.slider-custom {
  padding: 0 !important;
  color: #555555;
}

.slide-pane__header {
  display: flex;
  flex: 0 0 0px;
  align-items: left;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.slide-pane__title-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 0px;
  min-width: 0;
}

.slide-pane .slide-pane__title {
  font-size: 15px;
  font-weight: normal;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
  color: rgba(242, 94, 53, 1);
  font-weight: bold;
}

.slide-pane__close {
  margin-left: 10px;
  padding: 10px;
  opacity: 0.7;
  cursor: pointer;
}

.slide-pane__close svg {
  width: 8px;
  padding: 0;
  color: rgba(242, 94, 53, 1);
}

.slide-pane__content {
  position: relative;
  overflow-y: scroll;
  padding: 0;
  flex: 1 1 auto;
}

.placeholder {
  cursor: pointer;
  height: max-content;
}

.placeholder:hover {
  /* font-weight: bold; */
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.title {
  color: rgba(242, 94, 53, 1);
  font-weight: bold;
  padding: 1rem 2rem;
  margin-top: 3rem;
  font-size: 20px;
}

.slide-pane__overlay.ReactModal__Overlay--after-open {
  background-color: unset;
}

.slide-pane__header {
  border-bottom: unset;
  position: fixed;
  right: 10px;
  z-index: 10;
}

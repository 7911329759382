.trim-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
}

.slider-placeholder {
  display: flex;
  align-items: baseline;
}

.show-details {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-size: 9.05027px;
  line-height: 11px;
  color: #f25e35;
  padding-left: 5px;
}
.MuiTableBody-root > tr:nth-child(even) > .student {
  background-color: #edeced;
}
.MuiTableBody-root > tr:nth-child(odd) > .student {
  background-color: #fff;
}

.MuiTableBody-root > tr:hover > .student {
  background-color: #d7dae1 !important;
}

.student {
  white-space: nowrap;
  position: sticky;
  left: 0;
  z-index: 10;
}

.parent {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  margin-left: 10px;
}

.table-head {
  background: #282e3c;
  color: #ffffff;
  height: 55px;
}

.table-placement table {
  text-align: center;
  border-collapse: collapse;
  position: relative;
  width: 100%;
}

.table-placement th {
  text-align: left;
  padding-left: 10px;
  background: #282e3c;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.table-placement th:first-child {
  padding-left: 30px;
}

.table-placement th:last-child {
  padding-right: 20px;
}

.table-placement td {
  text-align: left;
  padding-left: 10px;
  padding-right: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  word-wrap: break-word;
}

.table-placement tr td:first-child {
  min-width: 135px;
}

.table-placement td:first-child {
  padding-left: 30px;
}

.container {
  padding-left: 27px;
}

.heading {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.headerDataHeading{
  display: flex;
  justify-content: space-between;
  padding: 15px 25px;
}
.customComponentHeading{
  padding: 5px;
}

.p-l {
  padding-left: 27px;
}

.p-r {
  padding-right: 27px;
}

.p-b {
  padding-bottom: 18px;
}
.mb-0 {
  margin-bottom: 0;
}
.divider {
  width: 100%;
  border-bottom: 1px solid #b7b7b7;
}

button {
  padding: 5px 10px;
  border: 1px solid #282e3c;
  background-color: transparent;
  color: #282e3c;
  border-radius: 5px;
  outline: none;
  font-family: Avenir;
  cursor: pointer;
}

h1 {
  margin-bottom: 5px;
  font-size: 1.5em;
}

h4 {
  margin-top: 0;
  font-weight: 500;
}

input,
select {
  border: 0.428597px solid #707070;
  box-sizing: border-box;
  border-radius: 3.00018px;
  height: 30px;
  font-family: Avenir;
  width: 100%;
  min-width: 100px;
}

.border-none {
  border: none;
}

.table-input {
  width: 100%;
}

.p-10 {
  padding: 10px;
}

tr:nth-child(even) {
  background-color: rgba(158, 158, 158, 0.2);
}

.student-details {
  position: fixed;
  width: 100%;
}

.table-placement {
  overflow-y: scroll;
  height: 100%;
  width: -webkit-max-content;
  width: max-content;
  min-width: 100%;
  display: inline-table;
}

textarea {
  position: absolute;
  top: 115px;
  width: 85%;
  left: 30px;
  /* z-index: -1; */
}

.push-down {
  position: absolute;
  top: 200px;
}

tr {
  vertical-align: middle;
}

.add-col {
  height: 170px;
  vertical-align: top;
  padding-top: 20px;
}

.emptyWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 40px;
}

.empty {
  font-family: 'AvenirDemi';
  font-size: 20px;
  padding-bottom: 5vh;
}

.headerWrapper {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}

.headerBlocks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headerHeading {
  font-family: 'Avenir';
  font-size: 14px;
}

.headerValue {
  font-family: 'Avenir';
  font-size: 16px;
  font-weight: 700;
}

.flexDiv {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}

.flagsDiv{
  display: flex;
  flex-direction: row;
  grid-gap:4px;
  gap:4px;
  margin-top: 4px;
}

.error {
  color: var(--oh-red);
  font-weight: 600;
}

.success {
  color: var(--oh-teal);
  font-weight: 600;
}

.slide-pane__overlay {
  z-index: 1;
}

.slider-custom {
  padding: 0 !important;
  color: #555555;
}

.slide-pane__header {
  display: flex;
  flex: 0 0;
  align-items: left;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.slide-pane__title-wrapper {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  margin-left: 0px;
  min-width: 0;
}

.slide-pane .slide-pane__title {
  font-size: 15px;
  font-weight: normal;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
  color: rgba(242, 94, 53, 1);
  font-weight: bold;
}

.slide-pane__close {
  margin-left: 10px;
  padding: 10px;
  opacity: 0.7;
  cursor: pointer;
}

.slide-pane__close svg {
  width: 8px;
  padding: 0;
  color: rgba(242, 94, 53, 1);
}

.slide-pane__content {
  position: relative;
  overflow-y: scroll;
  padding: 0;
  flex: 1 1 auto;
}

.placeholder {
  cursor: pointer;
  height: -webkit-max-content;
  height: max-content;
}

.placeholder:hover {
  /* font-weight: bold; */
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.title {
  color: rgba(242, 94, 53, 1);
  font-weight: bold;
  padding: 1rem 2rem;
  margin-top: 3rem;
  font-size: 20px;
}

.slide-pane__overlay.ReactModal__Overlay--after-open {
  background-color: unset;
}

.slide-pane__header {
  border-bottom: unset;
  position: fixed;
  right: 10px;
  z-index: 10;
}

#scheduler {
  height: 100vh;
  display: inline;
  display: initial;
  position: relative;
}
.button-alfred {
  background-color: #f3c520 !important;
  color: rgba(0, 0, 0, 0.8) !important;
  text-transform: lowercase !important;
  margin: 10px !important;
}

.button-alfred:hover {
  background-color: #ddb31c;
}

.trim-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
}

.slider-placeholder {
  display: flex;
  align-items: baseline;
}

.show-details {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-size: 9.05027px;
  line-height: 11px;
  color: #f25e35;
  padding-left: 5px;
}
.MuiTableBody-root > tr:nth-child(even) > .student {
  background-color: #edeced;
}
.MuiTableBody-root > tr:nth-child(odd) > .student {
  background-color: #fff;
}

.MuiTableBody-root > tr:hover > .student {
  background-color: #d7dae1 !important;
}

.student {
  white-space: nowrap;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 10;
}

.parent {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  grid-gap: 20px;
  gap: 20px;
  margin-left: 10px;
}

.group:before,
.group:after {
  content: "";
  display: table;
}
.group:after {
  clear: both;
}
.group {
  clear: both;
  *zoom: 1;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

a:hover {
  cursor: pointer;
}

.swal2-container {
  z-index: 100000 !important;
}

:root {
  --white: #fff;
  --oh-orange: #ef5c34;
  --oh-dark-orange: #de532e;
  --oh-light-orange: #f7eee9;
  --oh-white: #f5f5f5;
  --oh-light-cream: #fbf5ed;
  --oh-off-white: #f8f8f8; /* for payment card */
  --oh-black: #555555;
  --oh-black-light: #717171;
  --oh-black-tertiary: rgba(0, 0, 0, 0.36);
  --oh-black-secondary: rgba(0, 0, 0, 0.56);
  --oh-black-primary: rgba(0, 0, 0, 0.86);
  --oh-blue: #66b5de;
  --oh-teal: #95c48c;
  --oh-disabled: #d3d3d3;
  --oh-red: #e87d7c;
  --oh-green: #a3c996;
  --oh-vl-blue: rgba(102, 181, 222, 0.1);
  --oh-gray: rgba(0, 0, 0, 0.12);
  --oh-border-gray: rgba(0, 0, 0, 0.05);
  --oh-shadow-gray: rgba(64, 64, 64, 0.3);
  --oh-box-gray: #fafafa;

  --oh-brand-1: rgba(242, 183, 5, 1);
  --oh-brand-vl-1: rgba(242, 183, 5, 0.1);
  --oh-brand-2: rgba(140, 86, 55, 1);
  --oh-brand-vl-2: rgba(140, 86, 55, 0.1);
  --oh-brand-3: rgba(132, 108, 217, 1);
  --oh-brand-vl-3: rgba(132, 108, 217, 0.1);
  --oh-brand-4: rgba(191, 99, 105, 1);
  --oh-brand-vl-4: rgba(191, 99, 105, 0.1);
  --oh-brand-5: rgba(147, 204, 88, 1);
  --oh-brand-vl-5: rgba(147, 204, 88, 0.1);
  --oh-brand-6: rgba(94, 174, 1, 1);
  --oh-brand-vl-6: rgba(94, 174, 1, 0.1);
  --oh-brand-7: rgba(11, 158, 217, 1);
  --oh-brand-vl-7: rgba(11, 158, 217, 0.1);
  --oh-brand-8: rgba(255, 118, 107, 1);
  --oh-brand-vl-8: rgba(255, 118, 107, 0.1);
  --oh-brand-9: rgba(68, 137, 171, 1);
  --oh-brand-vl-9: rgba(68, 137, 171, 0.1);
}

@font-face {
  font-family: Avenir;
  src: local('Avenir Next'), url(/static/media/AvenirReg.073ab8d6.ttf);
  font-display: swap;
}
@font-face {
  font-family: AvenirDemi;
  src: local('Avenir Next'), url(/static/media/AvenirDemi.f957fd39.ttf);
  font-display: swap;
}
@font-face {
  font-family: AvenirMediumItalic;
  src: local('Avenir Next'), url(/static/media/AvenirMed.6268d167.ttf);
  font-display: swap;
}

@font-face {
  font-family: AvenirMedium;
  src: local('Avenir Next'),
    url(/static/media/AvenirNextRoundedProMedium.d777797e.ttf);
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Avenir';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

a {
  color: #ef5c34;
  color: var(--oh-orange);
  cursor: pointer;
  text-decoration: underline;
  font-weight: 700;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Scrollbar customizations currently only for webkit engine */

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.confirm-button,
.cancel-button {
  padding: 10px 20px !important;
  border-radius: 5px !important;
}

.confirm-button {
  background: #f3c520 !important;
  color: #000 !important;
}

.cancel-button {
  background: #fff !important;
  color: #000 !important;
  border: 1px solid #f3c520 !important;
}

.MuiMenuItem-root {
  font-family: 'Avenir' !important;
}

.MuiTable-root {
  margin: 0 !important;
}

.custom-root *{
  margin: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
}

.custom-root {margin-top: 4px;}

.table-head {
  background: #282e3c;
  color: #ffffff;
  height: 55px;
}

.table-placement table {
  text-align: center;
  border-collapse: collapse;
  position: relative;
  width: 100%;
}

.table-placement th {
  text-align: left;
  padding-left: 10px;
  background: #282e3c;
  position: sticky;
  top: 0;
}

.table-placement th:first-child {
  padding-left: 30px;
}

.table-placement th:last-child {
  padding-right: 20px;
}

.table-placement td {
  text-align: left;
  padding-left: 10px;
  padding-right: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  word-wrap: break-word;
}

.table-placement tr td:first-child {
  min-width: 135px;
}

.table-placement td:first-child {
  padding-left: 30px;
}

.container {
  padding-left: 27px;
}

.heading {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.headerDataHeading{
  display: flex;
  justify-content: space-between;
  padding: 15px 25px;
}
.customComponentHeading{
  padding: 5px;
}

.p-l {
  padding-left: 27px;
}

.p-r {
  padding-right: 27px;
}

.p-b {
  padding-bottom: 18px;
}
.mb-0 {
  margin-bottom: 0;
}
.divider {
  width: 100%;
  border-bottom: 1px solid #b7b7b7;
}

button {
  padding: 5px 10px;
  border: 1px solid #282e3c;
  background-color: transparent;
  color: #282e3c;
  border-radius: 5px;
  outline: none;
  font-family: Avenir;
  cursor: pointer;
}

h1 {
  margin-bottom: 5px;
  font-size: 1.5em;
}

h4 {
  margin-top: 0;
  font-weight: 500;
}

input,
select {
  border: 0.428597px solid #707070;
  box-sizing: border-box;
  border-radius: 3.00018px;
  height: 30px;
  font-family: Avenir;
  width: 100%;
  min-width: 100px;
}

.border-none {
  border: none;
}

.table-input {
  width: 100%;
}

.p-10 {
  padding: 10px;
}

tr:nth-child(even) {
  background-color: rgba(158, 158, 158, 0.2);
}

.student-details {
  position: fixed;
  width: 100%;
}

.table-placement {
  overflow-y: scroll;
  height: 100%;
  width: max-content;
  min-width: 100%;
  display: inline-table;
}

textarea {
  position: absolute;
  top: 115px;
  width: 85%;
  left: 30px;
  /* z-index: -1; */
}

.push-down {
  position: absolute;
  top: 200px;
}

tr {
  vertical-align: middle;
}

.add-col {
  height: 170px;
  vertical-align: top;
  padding-top: 20px;
}

.emptyWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 20px;
  margin-top: 40px;
}

.empty {
  font-family: 'AvenirDemi';
  font-size: 20px;
  padding-bottom: 5vh;
}

.headerWrapper {
  display: flex;
  gap: 16px;
}

.headerBlocks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headerHeading {
  font-family: 'Avenir';
  font-size: 14px;
}

.headerValue {
  font-family: 'Avenir';
  font-size: 16px;
  font-weight: 700;
}

.flexDiv {
  display: flex;
  align-items: center;
  gap: 16px;
}

.flagsDiv{
  display: flex;
  flex-direction: row;
  gap:4px;
  margin-top: 4px;
}

.error {
  color: var(--oh-red);
  font-weight: 600;
}

.success {
  color: var(--oh-teal);
  font-weight: 600;
}

.button-alfred {
  background-color: #f3c520 !important;
  color: rgba(0, 0, 0, 0.8) !important;
  text-transform: lowercase !important;
  margin: 10px !important;
}

.button-alfred:hover {
  background-color: #ddb31c;
}
